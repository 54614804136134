<template>
  <div class="box">
    <div class="box-top">
      <!-- <div class="box-tab" v-for="(item,i) in toptab" :key="i">{{ item }}</div> -->
      <el-radio-group
        class="tabradiobtn"
        v-model="toptabRadio"
        @change="onTabRadioChange"
      >
        <el-radio-button label="1">今日</el-radio-button>
        <el-radio-button label="2">本周</el-radio-button>
        <el-radio-button label="3">本月</el-radio-button>
      </el-radio-group>
      <div class="top-time">
        <el-date-picker
          v-model="dateRange"
          @change="onDateRangeChange"
          type="daterange"
          range-separator="-"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </div>
      <el-badge :value="200" :max="99" class="alarm">
        <el-button type="text" class="alarm-btn"
          ><img class="ding" src="../../assets/images/ding.png" alt=""
        /></el-button>
        <!-- size="small" -->
        <!-- <img class="ding" src="../../assets/images/ding.png" alt=""> -->
      </el-badge>
    </div>
    <div class="box-li">
      <div class="li-box" v-for="(item, i) in boxli" :key="i">
        <img src="../../assets/images/ding.png" alt="" />
        <div class="li-right">
          <div class="li-price">{{ item.price }}</div>
          <div class="li-name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="box-tj">
      <div class="tj-top">
        <div style="font-weight: 700">订单数据统计</div>
        <el-date-picker
          v-model="dateRange2"
          @change="onDateRangeChange2"
          type="daterange"
          range-separator="-"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </div>
      <div class="tj-box">
        <div class="tj" v-for="(item, i) in tjli" :key="i">
          <img :src="item.url" alt="" />
          <div class="tj-right">
            <div style="font-size: 14px">{{ item.name }}</div>
            <div class="number">{{ item.price }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-bottom">
      <div class="bot-left">
        <div class="l-top">
          <div style="font-weight: 700">营业额统计</div>
          <!-- <div style="margin-right: 40px;margin-left: 20px;">更新时间：2024-03-29 14:00:10</div> -->
          <el-date-picker
            v-model="dateRange3"
            @change="onDateRangeChange3"
            type="daterange"
            range-separator="-"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </div>
        <div style="margin-top: 10px; font-size: 14px">
          实收金额 {{ totalPrice }}元
        </div>
        <div ref="chart" style="width: 100%; height: 400px"></div>
      </div>
      <div class="bot-right">
        <div class="l-top">
          <div style="font-weight: 700">销售额排行</div>

          <el-date-picker
            v-model="dateRange4"
            @change="onDateRangeChange4"
            type="daterange"
            range-separator="-"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </div>
        <div ref="charts" style="width: 100%; height: 400px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import { transDate } from "@/utils/utils";
export default {
  data() {
    return {
      toptabRadio: "1",
      toptab: ["今日", "本周", "本月"],
      dateRange: [],
      dateRange2: [],
      dateRange3: [],
      dateRange4: [],
      boxli: [
        { price: "2000.00", name: "交易总额" },
        { price: "2000.00", name: "抽成总额" },
        { price: "2000.00", name: "充值总额" },
        { price: "2000.00", name: "积分抵扣" },
        { price: "2000.00", name: "优惠券抵扣" },
        { price: "2000.00", name: "新增会员数" },
      ],
      tjli: [
        {
          price: "2000.00",
          name: "下单单数",
          url: require("../../assets/images/tjicon1.png"),
        },
        {
          price: "2000.00",
          name: "支付金额",
          url: require("../../assets/images/tj2.png"),
        },
        {
          price: "2000.00",
          name: "微信支付金额",
          url: require("../../assets/images/tj2.png"),
        },
        {
          price: "2000.00",
          name: "总退款金额",
          url: require("../../assets/images/tj3.png"),
        },
        {
          price: "2000.00",
          name: "下单人数",
          url: require("../../assets/images/tj4.png"),
        },
      ],
      params: {
        timeType: 1, //时间类型1日 2周 3月 4自定义时间
        startTime: "",
        endTime: "",
      },
      value1: "",
      reportData: {},
      totalPrice: 0,
    };
  },
  created() {
    this.getData();
    this.getOrder();
  },
  mounted() {
    this.initChart();
    this.initCharts();
  },
  methods: {
    onTabRadioChange(val) {
      this.params.timeType = Number(val);
      this.getData();
      this.getOrder();
      this.initChart();
      this.initCharts();
      this.dateRange = [];
      this.dateRange2 = [];
      this.dateRange3 = [];
      this.dateRange4 = [];
    },
    onDateRangeChange(val) {
      if (val) {
        this.params.timeType = 4;
        this.params.startTime = val[0];
        this.params.endTime = val[1];
        this.getData();
        this.getOrder();
        this.initChart();
        this.initCharts();
        this.toptabRadio = "";
      } else {
        this.params.timeType = 1;
        this.getData();
        this.getOrder();
        this.initChart();
        this.initCharts();
        this.toptabRadio = 1;
      }
    },
    onDateRangeChange2(val) {
      if (val) {
        this.params.timeType = 4;
        this.params.startTime = val[0];
        this.params.endTime = val[1];
        this.getOrder();
        this.toptabRadio = "";
      } else {
        this.params.timeType = 1;
        this.getOrder();
        this.toptabRadio = 1;
      }
    },
    onDateRangeChange3(val) {
      if (val) {
        this.params.timeType = 4;
        this.params.startTime = val[0];
        this.params.endTime = val[1];
        this.initChart();
        this.toptabRadio = "";
      } else {
        this.params.timeType = 1;
        this.initChart();
        this.toptabRadio = 1;
      }
    },
    onDateRangeChange4(val) {
      if (val) {
        this.params.timeType = 4;
        this.params.startTime = val[0];
        this.params.endTime = val[1];
        this.initCharts();
        this.toptabRadio = "";
      } else {
        this.params.timeType = 1;
        this.initCharts();
        this.toptabRadio = 1;
      }
    },
    async initChart() {
      // 基于准备好的dom，初始化echarts实例
      const chartDom = this.$refs.chart;
      const myChart = echarts.init(chartDom);

      const res = await this.getAmountList();

      let xData = null;
      let seriesData = null;
      if (res.data.code === 200) {
        xData = res.data.data.map((item) => item.orderTime);
        seriesData = res.data.data.map((item) => item.totalAmount);
        this.totalPrice = seriesData
          .reduce((sum, price) => sum + price, 0)
          .toFixed(2);
      }

      // 定义图表配置和数据
      const option = {
        tooltip: {
          trigger: "axis",
          formatter: function (data) {
            let html = ``;
            html = `
           <div>${data[0].name}</div>
        <div>累计收益：${data[0].data}元</div>
        `;
            return html;
          },
        },
        xAxis: {
          type: "category",
          data: xData,
        },
        yAxis: [
          {
            type: "value",
          },
          {
            type: "value",
          },
        ],
        series: [
          {
            data: seriesData,
            type: "line",
          },
          // {
          //   data: [15, 23, 22, 21, 13, 14, 26],
          //   type: "line",
          // },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    async initCharts() {
      // 基于准备好的dom，初始化echarts实例
      const chartDom = this.$refs.charts;
      const myChart = echarts.init(chartDom);

      const res = await this.getOrderNumList(); // businessName
      let xData = null;
      let seriesData = null;
      if (res.data.code == 200) {
        xData = res.data.data.map((item) => item.businessName);
        seriesData = res.data.data.map((item) => item.totalAmount);
      }

      // 定义图表配置和数据
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xData,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "销售额",
            type: "bar",
            color: "skyblue",
            barWidth: "60%",
            data: seriesData,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    async getData() {
      const now = new Date();
      // const startOfDay = new Date(now.setHours(0, 0, 0, 0));
      // const endOfDay = new Date(now.setHours(23, 59, 59, 999));
      // this.params.startTime= transDate(startOfDay, "yyyy-MM-dd HH:mm:ss");
      // this.params.endTime = transDate(endOfDay, "yyyy-MM-dd HH:mm:ss");
      const res = await this.$http.post("orderReport/totalList", this.params);

      if (res.data.code === 200) {
        this.boxli[0].price = res.data.data.totalAmount;
        this.boxli[1].price = res.data.data.totalCommission;
        this.boxli[2].price = res.data.data.totalRecharge;
        this.boxli[3].price = res.data.data.totalCoupon;
        this.boxli[4].price = res.data.data.totalIntegral;
        this.boxli[5].price = res.data.data.totalNewMember;
      } else {
        this.$message.error(res.data.message);
      }
    },
    async getOrder() {
      const res = await this.$http.post("orderReport/orderList", this.params);

      if (res.data.code === 200) {
        this.tjli[0].price = res.data.data.orderNum;
        this.tjli[1].price = res.data.data.totalAmount;
        this.tjli[2].price = res.data.data.wxAmount;
        this.tjli[3].price = res.data.data.totalRefund;
        this.tjli[4].price = res.data.data.orderPeople;
      } else {
        this.$message.error(res.data.message);
      }
    },
    async getAmountList() {
      const res = await this.$http.post("orderReport/amountList", this.params);
      return res;
    },
    async getOrderNumList() {
      const res = await this.$http.post(
        "orderReport/orderNumList",
        this.params
      );
      return res;
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .box-top {
    display: flex;
    align-items: center;
    justify-content: right;

    height: 75px;
    background-color: #ffffff;
    .box-tab {
      width: 70px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      border-radius: 5px;
      background-color: #409eff;
      border: 1px solid rgba(121, 121, 121, 0.15);
      font-size: 15px;
      margin-right: 20px;
    }
    .tabradiobtn {
      margin-right: 14px;
    }
    .alarm {
      margin-left: 20px;
      margin-right: 40px;
      .alarm-btn {
        margin: 0;
        padding: 0;
      }
    }
    .ding {
      width: 40px;
      height: 40px;
    }
  }
  .box-li {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .li-box {
      width: 15%;
      height: 135px;
      background-color: #ffffff;
      display: flex;

      box-sizing: border-box;
      padding-left: 20px;
      align-items: center;

      img {
        width: 45px;
        height: 45px;
        border-radius: 50px;
      }
      .li-right {
        margin-left: 5px;
        .li-name {
          font-size: 14px;
          color: #838383;
          margin-top: 10px;
        }
      }
    }
  }
  .box-tj {
    height: 225px;
    margin-top: 20px;
    border-radius: 5px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding-top: 20px;
    .tj-top {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
    }
    .tj-box {
      display: flex;
      .tj {
        width: 20%;
        display: flex;

        height: 150px;
        padding-top: 30px;
        padding-left: 80px;
        box-sizing: border-box;
        img {
          width: 25px;
          height: 25px;
        }
        .tj-right {
          margin-left: 15px;
          .number {
            margin-top: 30px;
            font-weight: 700;
          }
        }
      }
    }
  }
  .box-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    box-sizing: border-box;

    .bot-left {
      width: 48%;
      height: 500px;
      padding-left: 15px;
      background-color: #ffffff;
    }
    .bot-right {
      width: 48%;
      height: 500px;
      padding-left: 15px;
      background-color: #ffffff;
    }
    .l-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      padding-right: 15px;
    }
  }
}
</style>
